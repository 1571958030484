"use client";
import React, { useState, useTransition } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";
import { UseMutationResult } from "@tanstack/react-query";

type ServerActionFunctionWithId = (id: string, req: any) => Promise<any>;
type ServerActionFunctionWithoutId = (req: any) => Promise<any>;

interface Props {
  open: boolean;
  name: string;
  confirmFunc?: any;
  loadingButton?: boolean;
  mutation?: {
    op: UseMutationResult<any, unknown, any, unknown>;
    req: any;
  };
  serverAction?: {
    id?: string;
    action: ServerActionFunctionWithId | ServerActionFunctionWithoutId;
    req: any;
  };
  after?: () => void;
}

export const confirmDeleteModal = atom<Props>({
  key: "confirmDeleteModal",
  default: {
    open: false,
    name: "",
    confirmFunc: null,
    loadingButton: false,
  },
});

export const ConfirmDeleteModal = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    name,
    open,
    confirmFunc,
    loadingButton,
    mutation,
    serverAction,
    after,
  } = useRecoilValue(confirmDeleteModal);
  const setConfirmDelete = useSetRecoilState(confirmDeleteModal);
  const [isPending, startTransition] = useTransition();

  const handleYes = async () => {
    if (!mutation && !confirmFunc && !serverAction) {
      throw new Error("no confirm func or mutation supplied");
    }
    if (mutation) {
      setLoading(true);
      await mutation.op.mutateAsync(mutation.req);
      setLoading(false);
      handleClose();
    }
    if (confirmFunc) {
      if (loadingButton) {
        setLoading(true);
        confirmFunc().finally(() => {
          setLoading(false);
          handleClose();
        });
      } else {
        confirmFunc().finally(() => {
          handleClose();
        });
      }
    }
    if (serverAction) {
      startTransition(async () => {
        const { id, action, req } = serverAction;

        const actionPromise = id
          ? (action as (id: string, req: unknown) => Promise<unknown>)(id, req)
          : (action as (req: unknown) => Promise<unknown>)(req);

        actionPromise.finally(() => {
          handleClose();
        });
      });
    }

    if (after) {
      after();
    }
  };

  const handleClose = () => {
    setConfirmDelete({ name, confirmFunc, loadingButton, open: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name}?
          <br />
          <Typography
            sx={{ fontStyle: "italic", fontSize: "0.8em" }}
            variant={"body2"}
            component={"span"}
            color={"secondary.main"}
          >
            This is irreversible
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={handleClose}>
          No
        </Button>
        <LoadingButton
          loading={loading || mutation?.op.isPending || isPending}
          variant={"outlined"}
          onClick={handleYes}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
