"use client";
import { Box, Icon, Tooltip, Typography } from "@mui/material";
import { Star } from "@mui/icons-material";
import React from "react";
import { useSets } from "modules/common/hooks/sets";
import { AutocompleteMagicCard } from "modules/cards/model/card";
import { TooltipCardImage } from "modules/common/components/TooltipCardImage";

interface Props {
  option: AutocompleteMagicCard;
  props: React.HTMLAttributes<HTMLLIElement>;
}

export default function AutocompleteListItem({ props, option }: Props) {
  const { query } = useSets();
  return (
    <TooltipCardImage card={option}>
      <li {...props} key={option.id}>
        <Box display={"flex"} gap={2} flexGrow={1}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            flexGrow={1}
          >
            <Typography variant={"body1"}>{option.name}</Typography>
            {/*<Typography>*/}
            {/*    {option.frameEffects?.join(",")}*/}
            {/*</Typography>*/}
            <Typography variant={"subtitle2"} color={"secondary"}>
              {option.price && option.price.default ? (
                `€ ${option.price.default.toFixed(2)}`
              ) : (
                <>&nbsp;</>
              )}
            </Typography>
          </Box>
          <Tooltip title={query.data && query.data[option.setCode]?.name}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box component={"span"}>
                {option.isPromo ? (
                  <Box
                    component={"span"}
                    sx={{
                      position: "absolute",
                      color: "text.secondary",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      zIndex: 100,
                      pt: "20px",
                      pl: "16px",
                    }}
                  >
                    <Star
                      sx={{
                        height: "12px",
                        width: "12px",
                        stroke: "#000",
                        strokeWidth: "1px",
                      }}
                    />
                  </Box>
                ) : null}
                <Typography
                  fontSize={"0.8rem"}
                  sx={{
                    mt: 0.5,
                  }}
                >
                  <Icon
                    baseClassName={`ss ss-${
                      option.isPromo
                        ? option.setCode.slice(1).toLowerCase()
                        : option.setCode.toLowerCase()
                    } ss-2x`}
                  />
                </Typography>
              </Box>
              <Box flexShrink={1}>
                <Typography
                  variant={"caption"}
                  color={"secondary"}
                  component={"span"}
                  sx={{
                    fontSize: "0.7rem",
                  }}
                >
                  {option.number}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </li>
    </TooltipCardImage>
  );
}
