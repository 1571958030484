"use client";
import React, { useEffect, useState } from "react";
import { CardPreviewModalImageStyle, CardPreviewSizing } from "utils/styles";
import { Box, CardMedia } from "@mui/material";
import { CardSkeleton } from "modules/common/components/CardSkeleton";
import { illustrationLink } from "utils/utils";
import { AutocompleteMagicCard, MagicCard } from "modules/cards/model/card";
import Image from "next/image";
import { DeckCardData } from "modules/decks/model/deck";
import { TradeCard } from "modules/swap-calculator/model/tradeCard";

interface Props {
  card: MagicCard | AutocompleteMagicCard | DeckCardData | TradeCard;
  foil?: boolean;
  isBack?: boolean;
  sizing?: any;
}

export const CardPreviewLoading = (props: Props) => {
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [src, setSrc] = useState<string>("");

  const onImageLoad = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    let id = "";
    if ("scryfallId" in props.card) {
      id = props.card.scryfallId;
    }
    if ("identifiers" in props.card) {
      id = props.card.identifiers.scryfallId;
    }
    const link = illustrationLink(id, props.isBack);
    if (src !== link) {
      setImageLoading(true);
      setSrc(link);
    }
    if (!("scryfallId" in props.card || "identifiers" in props.card)) {
      setSrc("");
    }
  }, [props.card, props.isBack, src]);
  return (
    <Box
      sx={{
        ...CardPreviewSizing,
        ...{
          boxSizing: "border-box",
          borderRadius: "4.75% / 3.5%",
          boxShadow: "1px 1px 8px rgb(0 0 0 / 50%)",
          objectFit: "cover",
          backgroundColor: "background.paper",
        },
        ...props.sizing,
      }}
    >
      {imageLoading ? (
        <Box
          sx={{
            position: "relative",
            zIndex: 20,
          }}
        >
          <CardSkeleton
            manaCost={props.card.manaCost}
            isLoading={!!props.card.id}
          />
        </Box>
      ) : null}
      {src !== "" ? (
        <CardMedia>
          <Box
            sx={{
              position: "relative",
            }}
          >
            {props.foil && !imageLoading ? (
              <Box
                className={"moving-rainbow-gradient"}
                sx={{
                  ...CardPreviewSizing,
                  position: "absolute",
                  // zIndex: 1,
                  borderRadius: "4.75% / 3.5%",
                  ...props.sizing,
                }}
              />
            ) : null}
            {props.card && src ? (
              <Image
                className={props.foil ? "moving-rainbow-gradient" : ""}
                width={240}
                height={336}
                quality={100}
                priority={true}
                src={src}
                alt={props.card.name}
                style={{
                  ...CardPreviewModalImageStyle,
                  ...{
                    visibility: imageLoading ? "hidden" : "visible",
                  },
                  ...props.sizing,
                }}
                onLoadCapture={() => onImageLoad()}
              />
            ) : null}
          </Box>
        </CardMedia>
      ) : null}
    </Box>
  );
};
