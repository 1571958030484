import {
  CardConditionEnum,
  CardLanguageEnum,
  FinishEnum,
  MagicCard,
} from "modules/cards/model/card";
import { SortOrderEnum } from "modules/collections/details/model/collection";
import { ShortTradeList } from "modules/decks/model/deck";
import { ShortLGS, ShortUser } from "modules/common/model/models";

export interface TradeListItemModel {
  userId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  id: string;
  type: TradeListType;
  itemsCount: number;
  deckId?: string;
  deckName?: string;
  published?: boolean;
}

export interface TradeListDetails {
  createdAt: string;
  lastUpdated: string;
  name: string;
  id: string;
  type: TradeListType;
  userId: string;
  deckId?: string;
  itemsCount: number;
  published?: boolean;
}

export enum TradeListType {
  WANTS = 1,
  TRADES,
}

export enum TradeListItemType {
  CARDS = 1,
}

export interface TradeListCreateRequest {
  name: string;
  itemType: TradeListItemType;
  type: TradeListType;
  cardCounts?: ItemCounts;
  deckId?: string;
}

export interface TradeListCreateResponse {
  list: TradeListItemModel;
  message: "created" | "updated";
}

export interface TradeListAddItemRequest {
  listId: string;
  dataId: string;
  finishes: FinishEnum[];
  language: CardLanguageEnum;
  condition: CardConditionEnum;
  quantity: number;
  anyPrinting: boolean;
}

export interface TradeListRemoveItemRequest {
  listId: string;
  id: string;
}

export interface TradeListUpdateItemRequest {
  listId: string;
  id: string;
  finishes: FinishEnum[];
  language: CardLanguageEnum;
  condition: CardConditionEnum;
  dataId: string;
}

export enum TradeListVisibilityEnum {
  Private,
  Public,
}

export enum TradeListSortOptions {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Name = "name",
}

export enum TradeListItemsSortOptionsEnum {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Name = "name",
  Price = "price",
}

export interface TradeListPagedRequest {
  userId: string;
  name: string;
  page: number;
  perPage: number;
  sort: TradeListSortOptions;
  order: SortOrderEnum;
  type?: TradeListType | null;
  wotcId?: number;
}

export interface TradeListPagedResponse {
  lists: TradeListItemModel[];
  hasMore: boolean;
  total: number;
}

export interface TradeListItemsRequest {
  id: string;
  page: number;
  perPage: number;
  sort: ItemSortOptionsEnum;
  order: SortOrderEnum;
  query: string;
  listType?: TradeListType;
}

export interface PagedTradeListItemsResponse {
  items: TradeItem[];
  hasMore: boolean;
  total: number;
}

export interface ItemCounts {
  [key: string]: number;
}

export enum TradeListItemsViewEnum {
  Table,
  Visual,
}

export interface TradeItem {
  listId: string;
  dataId: string;
  createdAt: string;
  updatedAt: string;
  data: MagicCard;
  ids: string[];
  quantity: string;
  list: ShortTradeList;
  finishes: FinishEnum[];
  language: CardLanguageEnum;
  condition: CardConditionEnum;
  user: ShortUser;
  anyPrinting: boolean;
}

export enum ItemSortOptionsEnum {
  Name = "name",
  Price = "price",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Quantity = "quantity",
}

export const TradeItemViews = [
  {
    name: "Visual",
  },
  {
    name: "Table",
  },
];

export interface TradeListAppraisalRequest {
  listId: string;
}

export interface TradeListAppraisalResponse {
  total: number;
  totalLength: number;
  distinctLength: number;
}

export interface DeleteTradeListResponse {
  deckId: string;
}

export interface TradeListDeleteBulkItemsRequest {
  listId: string;
  ids: string[];
}

export interface TradeListDeleteBulkItemsResponse {
  deleted: number;
}

export interface RecentTradeCard {
  tradeListId: string;
  tradeListName: string;
  username: string;
  addedAt: Date;
  stores: ShortLGS[];
  tradeItem: TradeItem;
}
